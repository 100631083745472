import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import {
  Container,
  Divider,
  Header,
  Grid,
  Image,
  Segment,
} from "semantic-ui-react";

import asimovLogo from "../../data/img/partners/asimov-logo.svg";
import cortevaLogo from "../../data/img/partners/corteva-logo.svg";
import dowLogo from "../../data/img/partners/dow-logo.svg";
import twistLogo from "../../data/img/partners/twist-logo.svg";
import { withLayout } from "../components/Layout";

interface SolutionsPageProps {
  data: {
    dowFeature: any;
    asimovFeature: any;
    cortevaFeature: any;
    twistFeature: any;
    foundryFeature: any;
  };
}

const SolutionsPage = (props: SolutionsPageProps) => {
  const {
    dowFeature,
    asimovFeature,
    cortevaFeature,
    twistFeature,
    foundryFeature,
  } = props.data;

  return (
    <Segment padded="very">
      <Container>
        <Header as="h1" textAlign="center">
          Solutions
        </Header>

        <Divider hidden />
        <Divider hidden />

        <Grid columns="2" stackable>
          <Grid.Row className="solution-row">
            <Grid.Column width={5}>
              <Img alt="Dow" fluid={dowFeature.childImageSharp.fluid} />
            </Grid.Column>
            <Grid.Column width={11} verticalAlign="middle">
              <Header as="h2">Discover</Header>
              <p>
                Lattice works with customers to examine their existing workflows
                and software to find opportunities to improve efficiency, lower
                cost, and improve results. These consulting engagements produce
                complete reports that can be used as the starting point for
                in-house software tools or custom software development
                engagements with Lattice.
              </p>
              <div className="customer-section">
                Featured engagement
                <Image alt="DOW logo" src={dowLogo} size="tiny" />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row className="solution-row">
            <Grid.Column width={11} verticalAlign="middle">
              <Header as="h2">Develop</Header>
              <p>
                Lattice works directly with customers on large and small custom
                software engagements. These begin with initial customer
                discussions translating into 6-12 month development
                partnerships. We offer flexible licensing and owernship terms
                and work on a milestone based development and payment schedule.
                We automated Corteva Argiscience's yeast design platform so that
                high-quality vector designs are generated in seconds, enabling
                their vector designers to rapidly construct plant expression
                vectors without design errors that would minimize assembly
                efficiency <i>in vivo</i>.
              </p>
              <div className="customer-section">
                Featured engagement
                <Image alt="Corteva logo" src={cortevaLogo} size="tiny" />
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <Img alt="Corteva" fluid={cortevaFeature.childImageSharp.fluid} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row className="solution-row">
            <Grid.Column width={5}>
              <Img alt="Asimov" fluid={asimovFeature.childImageSharp.fluid} />
            </Grid.Column>
            <Grid.Column width={11} verticalAlign="middle">
              <Header as="h2">Design</Header>
              <p>
                Lattice licenses its own in-house software to companies. This
                software builds on 12+ years bio-design automation research and
                covers multiple aspects of the Specification, Design, Build,
                Test, and Learn cycle. Examples of this design software include
                the Eugene&trade; domain specific language and the "Loom"
                platform for end-to-end synthetic biology development.
              </p>
              <div className="customer-section">
                Featured engagement
                <Image alt="Asimov logo" src={asimovLogo} size="tiny" />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row className="solution-row">
            <Grid.Column width={11} verticalAlign="middle">
              <Header as="h2">Democratize</Header>
              <p>
                Lattice engages with biotechnology companies to make their
                services available more broadly. We do this by either
                integrating their offerings into our software tools or creating
                standalone software tools for them. These projects include the
                development of APIs (e.g. for DNA sequencing or synthesis), the
                curation and creation of shared repositories of biological
                information and data (e.g. Parts registries), and the
                integration of vendor specific techniques into customer
                workflows (e.g. assembly methods).
              </p>
              <div className="customer-section">
                Featured engagement
                <Image alt="Twist logo" src={twistLogo} size="tiny" />
              </div>
            </Grid.Column>
            <Grid.Column width={5}>
              <Img alt="Twist" fluid={twistFeature.childImageSharp.fluid} />
            </Grid.Column>
          </Grid.Row>

          <Divider />

          <Grid.Row className="solution-row">
            <Grid.Column width={5}>
              <Img alt="Asimov" fluid={foundryFeature.childImageSharp.fluid} />
            </Grid.Column>
            <Grid.Column width={11} verticalAlign="middle">
              <Header as="h2">Foundry in a box</Header>
              <p>
              Foundry in a box services provide biomanufacturers with a complete software 
              toolset to go from design specification to physical assembly. These services 
              include high-level structural and performance specifications of the design requirements, 
              translation of those requirements to biological building blocks (e.g. DNA and RNA networks), 
              and fabrication planning algorithms to physically manufacture the design. These services 
              are augmented with hardware-specific drivers for lab equipment, electronic microbiology 
              protocols, and laboratory information management software. To validate these workflows, 
              sample data is also provided along with software to help use machine learning techniques to 
              tune the protocols for environmental and context-specific conditions.
              </p>
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Container>
    </Segment>
  );
};

// from https://www.gatsbyjs.org/docs/working-with-images/
export const partnersImg = graphql`
  fragment partnersImg on File {
    childImageSharp {
      fluid(maxWidth: 100, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

// from https://www.gatsbyjs.org/docs/working-with-images/
export const solutionsImg = graphql`
  fragment solutionsImg on File {
    childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const query = graphql`
  query {
    asimovFeature: file(
      relativePath: { eq: "img/solutions/website-feature-asimov.png" }
    ) {
      ...solutionsImg
    }

    cortevaFeature: file(
      relativePath: { eq: "img/solutions/website-feature-corteva.png" }
    ) {
      ...solutionsImg
    }

    twistFeature: file(
      relativePath: {
        eq: "img/solutions/website-feature-twist-api-reference-docs.png"
      }
    ) {
      ...solutionsImg
    }

    foundryFeature: file(
      relativePath: {
        eq: "img/solutions/website-feature-foundry.png"
      }
    ) {
      ...solutionsImg
    }

    dowFeature: file(
      relativePath: { eq: "img/solutions/website-feature-dow.png" }
    ) {
      ...solutionsImg
    }
  }
`;

export default withLayout(SolutionsPage);
